// Angular
import { OnInit, OnDestroy, ChangeDetectorRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { LayoutUtilsService, BaseModel, AlertMessageType } from '../_base/crud';
import { MaytechService } from './maytech.service';
import { ResultMessageType } from '../_utils/define';
import { TranslateService } from '@ngx-translate/core';
import { HelpEventService } from '../../views/partials/layout/help/help-event.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ComponentCanDeactivate } from '../_utils/can-deactivate-guard';


@Directive()
export class MaytechSimpleEditComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {
    // Public properties
    _object: BaseModel;
    loadingSubject = new BehaviorSubject<boolean>(true);
    loading$: Observable<boolean>;
    isLoading = false;
    objectForm: FormGroup;
    isFormChanges = false;
    hasFormErrors = false;
    isGoback = false;
    disabledEdit = false;
    listUrl = '';
    editPermission = '';
    protected isSubComponent = false; //sub component will not have it own url
    // Private password
    protected subscriptions: Subscription[] = [];

    constructor(
        public helpEventService: HelpEventService,
        protected permissionsService: NgxPermissionsService,
        public translate: TranslateService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected layoutUtilsService: LayoutUtilsService,
        protected formBuilder: FormBuilder,
        public cdr: ChangeDetectorRef,
        protected maytechDataService: MaytechService) {
        super();
    }
    canDeactivate(): boolean {
        return !this.isFormChanges;
    }

    ngOnInit() {
        this.loading$ = this.loadingSubject.asObservable();
        this.setLoading(true);
        // load object from url
        this.subscriptions.push(
            this.activatedRoute.params.subscribe(params => {
                const id = params['id'];
                this.initObject(id);
            })
        );
    }
    get object(): BaseModel {
        return this._object;
    }
    set object(value: BaseModel) {
        this._object = value;
    }
    initObject(id: number) {
        if (id && id > 0) {
            this.loadObjectFromDataService(id);
        } else {
            this.initNewObject();
        }
    }

    loadObjectFromDataService(objectId: number) {
        this.setLoading(true);
        //this.subscriptions.push(
        this.maytechDataService.getObjectById(objectId, false).toPromise().then(res => {
            if (res) {// success
                this.bindObjectToForm(res);
            }
            this.setLoading(false);
        }, err => {
            this.setLoading(false);
            this.onDataServiceError(err);
        });
        //);
    }

    initNewObject() {
        const newObj = new BaseModel();
        this.bindObjectToForm(newObj);
    }

    bindObjectToForm(_object) {
        if (!_object) {
            this.goBack('');
        }
        this.object = _object;
        this.createForm();
        this.checkEditPermision(this.editPermission);
        this.cdr.detectChanges();
        this.setLoading(false);
    }

    createForm() {
    }

    refreshComponent(isNew = false, id = 0) {
        if (this.isSubComponent === false) {
            let url = this.router.url;
            if (isNew) {
                url = `${url}/${id}`;
                this.router.navigateByUrl(url);
            } else {//update
                this.router.navigateByUrl(url);
            }
        }
        //else {
        //    this.loadObjectFromDataService(id);
        //}
    }

    prepareObject(): BaseModel {
        //const controls = this.objectForm.controls;
        const _object = new BaseModel();
        return _object;
    }

    onSumbit(withBack = false) {
        this.hasFormErrors = false;
        this.isFormChanges = false;
        this.isGoback = withBack;
        this.setLoading(true);

        if (this.objectForm.invalid) {
            this.objectForm.markAllAsTouched();
            this.onSubmitFail();
            return;
        }

        let editedObject = this.prepareObject();

        if (!editedObject || !this.onCustomValidation(editedObject)) {
            this.onSubmitFail();
            return;
        }
        this.cdr.detectChanges();

        if (editedObject._id > 0) {
            this.updateObject(editedObject);
        } else {
            this.addObject(editedObject);
        }
    }

    getItemCssClassStatus(status: number) {
        switch (status) {
            case 0:
                return 'cancelled';
            case 1:
                return 'confirmed';
        }
        return '';
    }

    addObject(_object: BaseModel) {
        //this.subscriptions.push(
        this.maytechDataService.createObject(_object, false).toPromise().then(
            res => {
                this.setLoading(false);
                if (res._id > 0) {
                    if (this.isGoback) {
                        this.goBackWithoutId();
                    } else {
                        this.refreshComponent(true, res._id);
                    }
                    this.onCreateDataSuccess(res);
                } else {
                    this.onDataServiceError({ status: 0, mesage: "Unknown error" });
                }
            },
            err => {
                this.onSubmitFail();
                this.onDataServiceError(err);
            }
        );
        //);
    }


    updateObject(_object: BaseModel) {
        console.log(_object);
        //this.subscriptions.push(
        this.maytechDataService.updateObject(_object, false).toPromise().then(
            res => {
                this.setLoading(false);
                if (res) {
                    if (this.isGoback) {
                        this.goBackWithoutId();
                    } else {
                        this.refreshComponent(false, res._id);
                    }
                    this.onUpdateDataSuccess();
                } else {
                    this.onDataServiceError({ status: 0, mesage: "Unknown error" });
                }
            },
            err => {
                this.onSubmitFail();
                this.onDataServiceError(err);
            }
        );
        //);
    }

    onCustomValidation(_object: BaseModel): boolean {
        return true;
    }
    onSubmitFail() {
        this.setLoading(false);
        this.hasFormErrors = true;
        this.cdr.detectChanges();
    }

    onCreateDataSuccess(res) {
        this.showMessage(ResultMessageType.CREATED);
    }

    onUpdateDataSuccess() {
        this.showMessage(ResultMessageType.UPDATED);
    }

    onDataServiceError(err) {
        this.showMessage(ResultMessageType.SAVE_FAILED);
    }

    onRequestEditSuccess() {
        this.showMessage(ResultMessageType.REQUEST_EDIT_SUCCESS);
    }


    showMessage(resultMessage: ResultMessageType) {
        const message = this.getResultMessageTypeLabel(resultMessage);
        this.layoutUtilsService.showActionNotification(message, AlertMessageType.Update, 5000, true, true);
    }

    getResultMessageTypeLabel(resultMessageType: number): string {
        return this.translate.instant("ENUM.ResultMessageType." + ResultMessageType[(<ResultMessageType>resultMessageType)]);
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    showHelpContext(controlName: string, label: string = '') {
        this.helpEventService.showHelpContent(controlName, label);
    }

    setLoading(isLoading: boolean) {
        if (this.isLoading != isLoading) {
            this.loadingSubject.next(isLoading);
            this.isLoading = isLoading
        }
    }

    setFormChanged(isChange: boolean) {
        this.isFormChanges = isChange;
    }
    goBack(id) {
        this.setLoading(false);
        let url = `${this.router.url}/${id}`;
        this.router.navigateByUrl(url);
    }

    goBackWithoutId() {
        this.router.navigateByUrl(this.listUrl);
    }

    checkEditPermision(permission: string[] | string) {
        this.permissionsService.hasPermission(permission).then(
            (val) => {
                if (!val) {
                    this.objectForm.disable();
                    this.disabledEdit = true;
                }
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.objectForm.controls[controlName];
        if (!control) {
            return false;
        }
        let result = control.hasError(validationType) && (control.dirty || control.touched);
        if (validationType == '') {
            result = control.invalid && (control.dirty || control.touched);
            if (result) {
                return true;
            }
        }
        if (result) {
            var keyNames = Object.keys(control.errors);
            if (keyNames[0] != validationType) {
                return false;
            }
        }
        return result;
    }

    isSubControlHasError(parent: FormGroup, controlName: string, validationType: string): boolean {
        const control = parent.controls[controlName];
        if (!control) {
            return false;
        }

        let result = control.hasError(validationType) && (control.dirty || control.touched);
        if (validationType == '') {
            result = control.invalid && (control.dirty || control.touched);
            if (result) {
                return true;
            }
        }
        if (result) {
            var keyNames = Object.keys(control.errors);
            if (keyNames[0] != validationType) {
                return false;
            }
        }
        return result;
    }
}
