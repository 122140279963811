export enum CommonStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum RandomType {
    ALL = 0,
    STRING = 1,
    NUMBERIC = 2,
    SPECIALCHAR = 3,
    UPPERSTR = 4,
    LOWERSTR = 5
}

export enum CustomerType {
    CUSTOMER = 0,
    PARTICIPANT = 1
}

export enum Gender {
    FEMALE = 0,
    MALE = 1,
    OTHERS = 2,
}

export enum RoleStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    COREROLE = 2,
    DELETED = 255
}

export enum ProductStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum CronJobStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}


export enum CronJobType {
    CALLAPI = 0,
    CALLSTOREPROC = 1
}


export enum ResourceCategoryStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum ResourceInfoFieldStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum ProductCategoryStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum ServiceStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum BookingFieldStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum CusColList {
    FirstName = "FirstName",
    MiddleName = "MiddleName",
    LastName = "LastName",
    Email = "Email",
    Gender = "Gender",
    BirthDate = "BirthDate",
    Address = "Address",
    Country = "Country",
    Phone = "Phone"
}

export enum ContextHelpStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum CurrencyStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum CustomerStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    ONLINE_NEW = 2,
    DELETED = 255
}

export enum LocationStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum TenantStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum DictionaryStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum RunStatus {
    Error = 0,
    Success = 1,
    Running = 2
}

export enum PersonStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum AssetStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    DELETED = 255
}

export enum ProductPriceType {
    Person = 1,// You charge by the customer. This can be one same price for everyone, prices per category (Adult, Child...) or group prices.
    PersonGroup = 2, //Group prices will apply to bookings that matches the minimum and maximum quantities you select.
    PersonCustom = 3,//If our list doesn't fit perfectly, this allows you to choose you own label.
    Item = 4,//You charge by the item, for example bike or kayak hire.
    Fixed = 5//You have one single price per booking, which does not change based on quantities or participant numbers.
}

export const ProductPriceTypeLabel = new Map<number, string>([
    [ProductPriceType.Person, "By Person"],
    [ProductPriceType.PersonGroup, "By Person Group"],
    [ProductPriceType.PersonCustom, "By Person Custom"],
    [ProductPriceType.Item, "By Item"],
    [ProductPriceType.Fixed, "Fixed"]
]);

export enum GroupPriceType// for PersonType=group
{
    //PerPerson = 1,// Select Per person if you entered the individual price
    //Total = 2//Select Total if you entered the Total price for the whole group
    Basic = 0, //Basic
    Range = 1, // Per person
}

export const GroupPriceTypeLabel = new Map<number, string>([
    //[GroupPriceType.PerPerson, "By Person"],
    //[GroupPriceType.Total, "Total"]
    [GroupPriceType.Basic, "Giá cơ bản"],
    [GroupPriceType.Range, "Giá theo số lượng"]
]);

export enum DatesAvailable {
    Fixed = 1,//Allows you to select dates and times when this product is available. Gives you the flexibility to change the price and availability for each date/time, and to blackout dates.You will need to create sessions in your calendar to open availability.
    AnyDate = 2,//This means that a date is required, but the customer can choose any they wish. You cannot limit availability, blackout dates or vary your prices per period if you select this option.
    NotRequired = 3//For example if you sell open tickets that do not require a specific date
}

export enum Availability {
    Unlimited = 1,
    LimitedFixedPerson = 2
}

export enum ConfirmBookings {
    Manual = 1,
    Auto = 2
}

export enum TimeUnit {
    Minute = 1,
    Hour = 2,
    Day = 3
}

export enum ReceiverType {
    BookingPerson = 0,
    Participant = 1,
    All = 2
}

export enum Timeline {
    Manual = 0,
    ExpiredBooking = 1,
    StartTrip = 2,
    EndTrip = 3,
}

export class ComboItem {
    Value: number;
    Text: string;
}

export class ComboItemWithClass {
    Value: number;
    Text: string;
    Class: string;
}

export class ComboItemMultiLevel {
    Level: number;
    Parent: number;
    Value: any;
    Text: string;
}

export class ComboItemGroupBy {
    IsGroup: boolean;
    Level: number;
    Parent: number;
    Value: any;
    Text: string;
}

export class ShortCode {
    name: string;
    code: string;
    source: string;
}

export class PredefinedBlock {
    name: string;
    code: string;
    html: string;
}

export class ComboItemString {
    Value: string;
    Text: string;
}

export enum ControlBoxType {
    Text = 1,
    List = 2
}

export const ControlBoxTypeLabel = new Map<number, string>([
    [ControlBoxType.Text, "Text"],
    [ControlBoxType.List, "List"]
]);

export enum MainCurrency {
    True = 1,
    False = 0
}

export enum PredefineBlock {
    Hello = '<p style="color:red;">Hello</p>',
    World = '<p>World</p>',
}

export enum Language {
    VN = 'VN',
    EN = 'EN'
}
export enum ServicePriceType {
    CustomerChoice = 1,
    PerOrder = 2,
    PerQuantity = 3
}

export enum BookingFieldType {
    List = 1,
    CheckBox = 2,
    Text = 3,
    MultyLineText = 4,
    Date = 5,
    Phone = 6,
    Radio = 7,
    MultiCheckbox = 8,
    NoReply = 9,
    Number = 10,
    Email = 11
}

export enum ValidateFunction {
    Is = 1,
    IsNot = 2,
    Contains = 3,
    DoseNotContain = 4,
    StartsWith = 5,
    EndWith = 6,
    Age = 7,
    Equal = 8,
    Different = 9,
    Larger = 10,
    Smaller = 11,
    GreaterThanOrEqual = 12,
    LessThanOrEqual = 13,
    BMI = 14,
    IsForArray = 15,
    IsNotForArray = 16,
    IsForCheckbox = 17,
    IsNotForCheckbox = 18,
    Range = 199,
    IsEmpty = 200,
    IsNotEmpty = 201
}

export enum WarningOfListType {
    Is = 15,
    IsNot = 16,
    IsEmpty = 200,
    IsNotEmpty = 201
}

export enum WarningOfRadioType {
    Is = 15,
    IsNot = 16,
    IsEmpty = 200,
    IsNotEmpty = 201
}


export enum WarningOfTextType {
    Is = 1,
    IsNot = 2,
    Contains = 3,
    DoseNotContain = 4,
    StartsWith = 5,
    EndWith = 6,
    MinLength = 19,
    MaxLength = 20,
    IsEmpty = 200,
    IsNotEmpty = 201
}

export enum WarningOfCheckBoxType {
    Is = 17,
    IsNot = 18
}

export enum WarningOfDateType {
    Age = 7
}

export enum CheckBoxValue {
    Check = 1,
    Uncheck = 0
}

export enum WarningOfNumberType {
    Equal = 8,
    Different = 9,
    Larger = 10,
    Smaller = 11,
    GreaterThanOrEqual = 12,
    LessThanOrEqual = 13,
    Range = 199,
    BMI = 14,
    IsEmpty = 200,
    IsNotEmpty = 201

}

export enum HttpAPICallType {
    GetList,
    Create,
    Read,
    Update,
    Delete
}

export enum ExportType {
    Trip = 1,
    Booking = 2,
}



export enum ObjectType {
    Any = 255,
    ActionLog = 0,
    BookingField = 1,
    ContextHelp = 2,
    Currency = 3,
    Customer = 4,
    CustomerInfo = 5,
    CustomerStay = 6,
    Dictionary = 7,
    Document = 8,
    JoinTrip = 9,
    Location = 10,
    MetaData = 11,
    Option = 12,
    OrderPrice = 14,
    PaymentTransaction = 15,
    Permission = 16,
    PersonType = 17,
    Pickup = 18,
    PickupTourist = 19,
    Product = 20,
    ProductBookingField = 21,
    ProductCategory = 22,
    ProductInCate = 23,
    ProductPrice = 24,
    ProductSession = 25,
    ProductSessionPrice = 26,
    RecurringRule = 27,
    RelatedProduct = 28,
    Role = 29,
    RoleHasPermission = 30,
    Service = 31,
    ServiceInProduct = 32,
    Staff = 33,
    StaffServeTrip = 34,
    Tenant = 35,
    Trip = 36,
    UseService = 37,
    User = 38,
    UserDevice = 39,
    UserHasPermission = 40,
    UserRole = 41,
    MessageTemplate = 42,
    ProductSchedule = 43,
    ProductPickup = 44,
    MessageTemplateInProduct = 45,
    ServicePrice = 46,
    WidgetCss = 47,
    Auth = 48,
    ListView = 49,
    Column = 50,
    Booking = 51,
    BookingProductSession = 52,
    Payment = 53,
    BookingProductPrice = 54,
    BookingService = 55,
    BookingPayment = 56,
    BookingInfo = 57,
    BookingCustomer = 58,
    BookingLogNote = 59,
    ResourceCategory = 60,
    ProductResource = 61,
    MessageLog = 62,
    Person = 63,
    PersonInfo = 64,
    PersonInCate = 65,
    BookingRepresentor = 66,
    ResourceInfoField = 67,
    Asset = 68,
    AssetInfo = 69,
    AssetInCate = 70,
    CronJob = 71,
    CronJobLog = 72,
    GroupBy = 73,
    Manifest = 73,
    SupportTicket = 74,
    TicketComment = 75,
    ProductPaymentType = 76,
    TourCertificateTemplate = 77,
    TourCertificate = 78,
    ProductSessionService = 79,
    ObjectPrice = 80,
    Invoice = 81,
    TaxFee = 82,
    ProductTaxFee = 83,
    BookingSeller = 84,
    Report = 90,
    ProductBookingFieldWarning = 91,
    Contract = 95
}


export enum ActionType {
    GetList = 0,
    Create = 1,
    Read = 2,
    Update = 3,
    Delete = 4,
    UserRegister = 5,
    UserLogin = 6,
    UserLogout = 7,
    SendEmail = 8,
    Clone = 9,
    DisconnectDevice = 10,
    BookingLogNote = 11,
    Add = 12,
    ReasonNote = 13,
    Print = 14,
    ChangeCurrency = 15,
    Export = 16,
    UpdateParticipants = 17
}


export enum RepeatType {
    DoNotRepeat = 0,
    //Minutely = 1,
    //Hourly = 2,
    Daily = 3,
    Weekly = 4,
    Monthly = 5,
    Yearly = 6,
}


export enum MessageTemplateStatus {
    DEACTIVE = 0,
    ACTIVE = 1,
    Deleted = 255
}

export enum MessageType {
    Notifications = 0,
    Reminders = 1,
    //Followups = 2,
    //PaymentRequest = 3,
    Confirm = 4,
    TourCertificate = 5,
    Information = 6,
    Additional = 7,
    CancellationNotice = 8,
    Invoice = 9,
}

export enum MessageMethod {
    Email = 0,
    SMS = 1,
    Call = 3,
    Weblink = 4
}


export enum MessageSendStatus {
    JustCreated = 0,
    Sending = 1,
    WaitConfirm = 2,
    Done = 3,
    SendFail = 4,
    Deleted = 255
}
export enum MessageOpenStatus {
    JustCreated = 0,
    Delivered = 1,
    Opened = 2,
    Clicked = 3,
    Bounced = 4
}
export enum MessageRenderStatus {
    NeedRender = 0,
    Rendered = 1,
    RenderFailed = 2
}
export enum SentStatus {

    JustCreated = 0,
    Delivered = 1,
    Opened = 2,
    Clicked = 3,
    Bounced = 4
}

export enum UserErrorCode {
    EXISTS = 100
}

export enum SessionUpdateField {
    PRICE = 1,
    AVAILABILITY = 2,
    CONFIG = 3,
    //DEACTIVE = 3
}

export enum SendType {
    BEFORE = 0,
    AFTER = 1
}

export enum WidgetPlugin {
    ProductCalendar = 0,
    WeeklyCalendar = 1
}

export enum BookingSource {
    ONLINE = 0,
    INTERNAL = 1,
    PARTNER = 2
}

export enum BookingStatus {
    DRAFT = 0,
    PENDING = 1,
    ON_HOLD = 2,
    CONFIRMED = 3,
    CANCELLED = 4,
    WAITING = 5,
    PENDING_CHECK = 6,
    PENDING_PAYMENT = 7,
    WAIT_TO_SIGN = 8,
    DELETED = 255
}
export enum WarningStatus {
    DEFAULT = 0,
    WARNING = 1,
    RED_ALERT = 2,

}
export enum BookingCustomerStatus {
    CERTIFICATE_UNPUBLISH = 1,
    CERTIFICATE_PUBLISH = 2,
    DELETED = 255
}
export enum PaidStatus {
    UNPAID = 0,
    PARTIAL = 1,
    PAID = 2,
    REFUNDED = 3
}

export enum PaymentStatus {
    NEW = 0,
    SUCCESS = 1,
    UNSUCCESS = 2
}

export enum PaymentMethod {
    DOMESTIC = 0,
    INTERNATIONAL = 1,
    BANKTRANSFER = 2
}

export enum PaymentType {
    CASH = 0,
    CREDITCARD = 1,
    BANKTRANSFER = 2,
    DOMESTIC = 3,
    DISCOUNT = 4,
    REFUND = 5,
    SWIPE = 6,
    QRCODE = 7,
    CREDITCARD_OUTSIDE = 8,
    INSTALLMENT = 9,
    OTHER = 255
}

export enum PaymentTypeOnline {
    CREDITCARD = 1,
    BANKTRANSFER = 2,
    DOMESTIC = 3,
    QRCODE = 7,
    CREDITCARD_OUTSIDE = 8,
    INSTALLMENT = 9
}

export enum AmountUpdateType {
    NEW = 0,
    ADD = 1,
    MINUS = 2
}

export enum SupportTicketStatus {
    OPEN = 0,
    PROCESSING = 1,
    DONE = 2
}

export enum RoleType {
    GUEST = 0,
    ADMIN = 1,
    CUSTOM = 2
}

export enum ResourceType {
    PERSON = 0,
    ASSET = 1,
}

export enum ResourceCategoryType {
    PERSON = 0,
    ASSET = 1,
}

export enum QuotaType {
    TRIP = 0,
    PARTICIPANT = 1,
}

export enum ResultMessageType {
    SAVE_FAILED = 0,
    SAVE_SUCCEED = 1,
    CREATED = 2,
    UPDATED = 3,
    ERROR = 4,
    LOAD_LIST_ERROR = 5,
    REQUEST_EDIT_SUCCESS = 6,
    APPROVE_REQUEST_SUCCESS = 7,
    PLEASE_WAIT = 10
}

export enum BookingUpdateStatusReason {
    NO_REASON = 0,
    CUSTOMER = 1,
    STAFF = 2,
    OXALIS = 3
}

export enum ActionStatus {
    SUCCEED = 0,
    FAILED = 1
}

export enum ListViewType {
    LIST = 0,
    DETAIL = 1
}

export enum NotificationStatus {
    JUST_CREATED = 0,
    ALREADY_READ = 1
}

export enum Module {
    BOOKING = 1,
    SESSION = 2,
    PRODUCT = 3,
    RESOURCE = 4
}

export enum SubModule {
    BOOKING_CUSTOMER_TAB = 1,
    BOOKING_PRODUCT_TAB = 2,
    SESSION_INFO_TAB = 3,
    RESOURCE_PERSON = 4,
    RESOURCE_ASSET = 5,
    RESOURCE_CATEGORY = 6,
    RESOURCE_INFO_FIELD = 7,
    PRODUCT_DETAILS_TAB = 8
}

export enum EditRequestStatus {
    NEW = 0,
    APPROVED = 1,
    REJECTED = 2,
    DELETED = 255
}

export enum EditRequestType {
    List = 1,
    CheckBox = 2,
    Text = 3,
    MultyLineText = 4,
    Date = 5,
    Phone = 6,
    Radio = 7,
    MultiCheckbox = 8,
    NoReply = 9,
    Number = 10,
    Email = 11,
    Money = 12,
    PaymentType = 13,
    DateTime = 14,
    Time = 15,
    PaymentDate = 16
}

export enum TaxFeeValueType {
    PERCENT = 0,
    PARTICIPANT = 1,
    PRODUCT = 2
}

export enum TaxFeeType {
    TAX = 0,
    FEE = 1
}

export enum ProductTab {
    DETAILS = 0,
    CATEGORY = 1,
    SERVICE = 2,
    SCHEDULE = 3,
    BOOKING_FIELD = 4,
    RELATED = 5,
    RESOURCE = 6,
    TAXFEE = 7,
    MESSAGE = 8,
    PAYMENT = 9,
    WARNING = 10
}

export enum AssignTripStatus {
    NOT_DONE = 0,
    DONE = 1
}

export enum TripResourceStatus {
    NEW = 0,
    FULL = 1,
    OVER = 2
}

export enum AssignType {
    TRIP = 0,
    JOB = 1
}

export enum ContractStatus {
    NEW = 0,
    SEND_TO_SIGN = 1,
    SIGNED = 2,
    RESEND = 4,
    CANCELED = 5,
    TIMEOUT = 10,
    ERROR = 11,
    DELETED = 255,
}
