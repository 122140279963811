import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryResultsModel, QueryParamsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { ObjectType, HttpAPICallType } from '../../_utils/define';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { OptionErrorAction } from '../_actions/option.actions';
import { Observable } from 'rxjs';
import { OptionModel } from '..';
import { catchError } from 'rxjs/operators';

// Real REST API
@Injectable()
export class OptionService extends MaytechService {
    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService ) {

        super(http, httpUtils, route, store, tenantService);

        if (tenantService.currentClient != null)
            this.API_URL = tenantService.currentClient.apiurl + '/options';
    }
    getOptionByName(name: string): Observable<OptionModel> {
        const url = this.API_URL + `/get/${name}/`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<OptionModel>(url, {
            headers: httpHeaders
        }).pipe(
            catchError(this.handleError("getOptionByName", []))
        );
    }
    getOptionByNameNoCatchError(name: string): Observable<OptionModel> {
        const url = this.API_URL + `/get/${name}/`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<OptionModel>(url, {
            headers: httpHeaders
        });
    }
    getOptionListByName(name: string): Observable<OptionModel> {
        const url = this.API_URL + `/getList/${name}/`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        return this.http.get<OptionModel>(url, {
            headers: httpHeaders
        }).pipe(
            catchError(this.handleError("getOptionListByName", []))
        );
    }

    getShorecodeAndBlock(catchErr = true): Observable<QueryResultsModel> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        const url = this.API_URL;
        if (catchErr) {
            return this.http.get<QueryResultsModel>(url, { headers: httpHeaders})
                .pipe(catchError(this.handleError("findObjects", []))
                );
        }
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    }

    getErrorDispatcher(error: any, objectType: ObjectType = ObjectType.Any, httpAPICallType: HttpAPICallType = HttpAPICallType.GetList): MaytechBaseAction {
        return new OptionErrorAction(this.actionPrefix, {
            obj: {
                items: [],
                totalCount: 0,
                errorMessage: error.message,
                errorCode: error.status,
                pageIndex: 0
            }
        });
    }

    addUpdate(object: OptionModel): Observable<OptionModel> {
        const url = this.API_URL + `/addUpdate`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put<OptionModel>(url, object, { headers: httpHeaders });
    }
}
