import { MaytechService } from '../../_maytech/maytech.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService, QueryParamsModel, BaseModel, QueryResultsModel } from '../../../core/_base/crud';
import { MaytechTenantService } from '../../_maytech/maytech.tenant.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../_reducers';
import { Store } from '@ngrx/store';
import { MaytechBaseAction } from '../../_maytech/maytech.actions';
import { HttpAPICallType, ObjectType } from '../../_utils/define';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ListObjectIDModel } from '../../../models/listobject.model';
import { catchError, retry, filter, finalize } from 'rxjs/operators';
import { BookingUpdateStatusModel } from '../../../models/bookingupdatestatus.model';
import { BookingUpdateCurrencyModel } from '../../../models/bookingupdatecurrency.model';
import { BookingModel } from '..';
import { User } from '../../../models/user.model';
import { MessageTemplateModel } from '../../setting';
// Real REST API
@Injectable()
export class BookingService extends MaytechService {
    public _isLoading$ = new BehaviorSubject<boolean>(false);
    private clientApiUrl = '';
    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    constructor(http: HttpClient,
        httpUtils: HttpUtilsService,
        route: ActivatedRoute,
        public store: Store<AppState>,
        tenantService: MaytechTenantService) {

        super(http, httpUtils, route, store, tenantService);


        if (tenantService.currentClient != null) {
            this.API_URL = tenantService.currentClient.apiurl + '/booking';
            this.clientApiUrl = tenantService.currentClient.apiurl;
        }
    }

    getProductSessionsForBooking(productID: number): Observable<any> {
        const url = this.API_URL + "/GetProductSessionsForBooking/" + productID.toString();

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getProductSessionsForBooking", []))
        );
    }

    getBookingSessions(bookingID: number): Observable<any> {
        const url = this.API_URL + "/getBookingSessions/" + bookingID;

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getBookingSessions", []))
        );
    }

    getBookingTotalAmount(bookingID: number): Observable<any> {
        const url = this.API_URL + "/GetBookingTotalAmount/" + bookingID;

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getBookingTotalAmount", []))
        );
    }

    updateStatus(obj: BookingUpdateStatusModel): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + '/updateStatus';
        return this.http.put<any>(url, obj, { headers: httpHeaders });
    }

    updateBookingCurrency(obj: BookingUpdateCurrencyModel): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + '/updateBookingCurrency';
        return this.http.put<any>(url, obj, { headers: httpHeaders });
    }

    updateSendToEmails(booking: BookingModel): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + '/updateSendToEmails';
        return this.http.put<any>(url, booking, { headers: httpHeaders });
    }

    updateRepresentor(booking: BookingModel): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + '/updateRepresentor';
        return this.http.put<any>(url, booking, { headers: httpHeaders });
    }

    removeRepresentor(bookingID: number): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = `${this.API_URL}/removeRepresentor/${bookingID}`;
        return this.http.delete<BaseModel>(url, { headers: httpHeaders });
    }

    updateSeller(booking: BookingModel): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = this.API_URL + '/updateSeller';
        return this.http.put<any>(url, booking, { headers: httpHeaders });
    }

    removeSeller(bookingID: number): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const url = `${this.API_URL}/removeSeller/${bookingID}`;
        return this.http.delete<BaseModel>(url, { headers: httpHeaders });
    }

    initBooking(): Observable<BaseModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<BaseModel>(this.API_URL + '/initbooking', null, { headers: httpHeaders })
            .pipe(catchError(this.handleError("createObject", []))
            );
    }

    getBookingCode(bookingID: number) {
        const url = this.API_URL + "/GetBookingCode/" + bookingID;

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get(url, { headers: httpHeaders }).pipe(catchError(this.handleError("getBookingCode", [])));
    }

    findObjects(queryParams: QueryParamsModel, catchErr = true): Observable<QueryResultsModel> {
        this._isLoading$.next(true);

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = this.API_URL;
        if (catchErr) {
            return this.http.get<QueryResultsModel>(url, { headers: httpHeaders, params: httpParams })
                .pipe(catchError(this.handleError("findObjects", [])), finalize(() => this._isLoading$.next(false)));
        }
        return this.http.get<QueryResultsModel>(url, { headers: httpHeaders, params: httpParams })
            .pipe(finalize(() => this._isLoading$.next(false)));
    }

    getPdfFile(type, code: string): Observable<any> {
        const url = `${this.API_URL}/GetPdfFile/${code}/${type}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<Blob>(url, { headers: httpHeaders, observe: 'response', responseType: 'blob' as 'json' })
            .pipe(catchError(this.handleExportError("getPdfFile", [])));
    }

    getDecodedBookingUrl(code: string): Observable<any> {
        const url = `${this.clientApiUrl}/frontpage/getdecodedbookingurl/${code}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders})
            .pipe(catchError(this.handleExportError("getdecodedbookingurl", [])));
    }

    handleExportError<T>(operation: string = 'operation', result?: any, objectType: ObjectType = ObjectType.Any) {
        return (error: any): Observable<any> => {

            let message = "API Error!!!";
            if (error.error && error.error.errorMessage)
                message = error.error.errorMessage;
            else if (error.errorMessage)
                message = error.errorMessage;
            console.error(`Operation: ${operation}\nObjectType: ${objectType}\nError Code: ${error.status}\nMessage: ${message}`); // log to console
            console.error(`Error detial`);
            console.error(error);
            this.store.dispatch(this.getErrorDispatcher(error, objectType));

            // Let the app keep running by returning an empty result.
            return of();
        };
    }

    getBookingCreatedUserList(catchErr = true): Observable<User[]> {
        this._isLoading$.next(true);
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        const url = this.API_URL + "/GetBookingCreatedUserList/";
        if (catchErr) {
            return this.http.get<User[]>(url, { headers: httpHeaders })
                .pipe(catchError(this.handleError("getBookingCreatedUserList", [])), finalize(() => this._isLoading$.next(false)));
        }
        return this.http.get<User[]>(url, { headers: httpHeaders })
            .pipe(finalize(() => this._isLoading$.next(false)));
    }

    getBookingRepresentedByList(catchErr = true): Observable<User[]> {
        this._isLoading$.next(true);
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        const url = this.API_URL + "/GetBookingRepresentedByList/";
        if (catchErr) {
            return this.http.get<User[]>(url, { headers: httpHeaders })
                .pipe(catchError(this.handleError("getBookingRepresentedByList", [])), finalize(() => this._isLoading$.next(false)));
        }
        return this.http.get<User[]>(url, { headers: httpHeaders })
            .pipe(finalize(() => this._isLoading$.next(false)));
    }

    getBookingSellerList(catchErr = true): Observable<User[]> {
        this._isLoading$.next(true);
        const httpHeaders = this.httpUtils.getHTTPHeaders();

        const url = this.API_URL + "/GetBookingSellerList/";
        if (catchErr) {
            return this.http.get<User[]>(url, { headers: httpHeaders })
                .pipe(catchError(this.handleError("getBookingSellerList", [])), finalize(() => this._isLoading$.next(false)));
        }
        return this.http.get<User[]>(url, { headers: httpHeaders })
            .pipe(finalize(() => this._isLoading$.next(false)));
    }

    isValidToSendEmail(bookingId, msgTplId): Observable<any> {
        const url = this.API_URL + "/IsValidToSendEmail/" + bookingId + "/" + msgTplId;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<any>(url, { headers: httpHeaders }).pipe(catchError(this.handleError("Is Valid To Send Email", []))
        );
    }

    //getBookingFields(bookingID: number): Observable<any> {
    //    const url = this.API_URL + "/GetBookingFields/" + bookingID.toString();
    //    const httpHeaders = this.httpUtils.getHTTPHeaders();
    //    return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    //}

    //getUnselectedBookingFields(bookingID: number): Observable<any> {
    //    const url = this.API_URL + "/GetUnselectedBookingFields/" + bookingID.toString();
    //    const httpHeaders = this.httpUtils.getHTTPHeaders();
    //    return this.http.get<QueryResultsModel>(url, { headers: httpHeaders });
    //}

}
