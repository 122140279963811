// Angular
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

// NgBootstrap
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../core/core.module';
// CRUD Partials
import {
    ActionNotificationComponent,
    AlertComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    ConfirmDialogComponent,
    SaveConfirmDialogComponent,
    RequestEditDialogComponent,
    ApproveEditDialogComponent,
    EditRequestListDialogComponent,
    EditRequestHistoryDialogComponent,
    EditRequestDetailsDialogComponent,
    RefPricesDialogComponent,
    SimpleDialogComponent
} from './content/crud';
// Layout partials
import {
    ContextMenu2Component,
    ContextMenuComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    QuickActionComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    Subheader4Component,
    Subheader5Component,
    SubheaderSearchComponent,
    UserProfile2Component,
    UserProfile3Component,
    UserProfileComponent,
    HelpComponent,
    QuickHelpComponent,
    MtFilterDropdownComponent,
    MtSelectDropdownComponent,
    MtFilterDaterangeComponent,
    MtFilterTimeRangeComponent,
    MtEditRequestMenuComponent,
    MtEditRequestValueComponent,
    MtReferencePriceTableComponent,
    MtFilterRemainTimeComponent,
    MtFilterDropdownMultiLevelComponent
} from './layout';
// General
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
import {
    KtDialogService

} from '../../core/_base/layout';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { CartComponent } from './layout/topbar/cart/cart.component';
import { HelpEventService } from './layout/help/help-event.service';
import { SupportTicketService } from '../../core/theme/_services/supportticket.service';
import { TranslateModule } from '@ngx-translate/core';
import { ContextHelpService } from '../../core/setting';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { StoreModule } from '@ngrx/store';
//import { contextHelpReducer } from '../../core/setting/_reducers/contexthelp.reducers';
import { InterceptService } from '../../core/_base/crud';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FeedbackComponent } from './layout/topbar/feedback/feedback.component';
import { FeedbackDialogComponent } from './layout/topbar/feedback/_sub/feedback-dialog.component';
import { GeneralSearchService } from '../../core/theme';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MtLoaderComponent } from './layout/mt-loader/mt-loader.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { PrintTemplateModule } from './content/print-templates/print-template.module';
import { NotificationService } from '../../core/setting/_services/notification.service';
import { NotificationDetailDialogComponent } from './layout/topbar/notification/notification-detail/notification-detail.dialog.component';
export const MY_MOMENT_FORMATS = {
    parseInput: 'DD/MM/YYYY',
    fullPickerInput: 'HH:mm DD/MM/YYYY',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// material.module.ts
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ClipboardModule } from 'ngx-clipboard';
import { MetadataService } from '../../core/setting/_services/metadata.service';


@NgModule({
    declarations: [
        ScrollTopComponent,
        NoticeComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertComponent,
        SimpleDialogComponent,
        ConfirmDialogComponent,
        SaveConfirmDialogComponent,
        RequestEditDialogComponent,
        ApproveEditDialogComponent,
        EditRequestListDialogComponent,
        EditRequestHistoryDialogComponent,
        EditRequestDetailsDialogComponent,
        RefPricesDialogComponent,
        // topbar components
        ContextMenu2Component,
        ContextMenuComponent,
        QuickPanelComponent,
        ScrollTopComponent,
        SearchResultComponent,
        SplashScreenComponent,
        StickyToolbarComponent,
        Subheader1Component,
        Subheader2Component,
        Subheader3Component,
        Subheader4Component,
        Subheader5Component,
        SubheaderSearchComponent,
        LanguageSelectorComponent,
        NotificationComponent,
        NotificationDetailDialogComponent,
        QuickActionComponent,
        SearchDefaultComponent,
        SearchDropdownComponent,
        UserProfileComponent,
        UserProfile2Component,
        UserProfile3Component,
        CartComponent,
        ErrorComponent,
        HelpComponent,
        FeedbackComponent,
        FeedbackDialogComponent,
        MtLoaderComponent,
        QuickHelpComponent,
        MtFilterDropdownComponent,
        MtFilterDaterangeComponent,
        MtFilterTimeRangeComponent,
        MtSelectDropdownComponent,
        MtEditRequestMenuComponent,
        MtEditRequestValueComponent,
        MtReferencePriceTableComponent,
        MtFilterRemainTimeComponent,
        MtFilterDropdownMultiLevelComponent
        //StoreModule.forFeature('Contexthelp', contextHelpReducer('Contexthelp')),
    ],
    exports: [
        ClipboardModule,
        MatButtonToggleModule,
        DragDropModule,
        HttpClientModule,
        MatExpansionModule,
        MatSidenavModule,
        NgbProgressbarModule,
        NgbModule,
        WidgetModule,
        PortletModule,
        PrintTemplateModule,
        ScrollTopComponent,
        NoticeComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertComponent,
        SimpleDialogComponent,
        ConfirmDialogComponent,
        SaveConfirmDialogComponent,
        RequestEditDialogComponent,
        ApproveEditDialogComponent,
        EditRequestListDialogComponent,
        EditRequestHistoryDialogComponent,
        EditRequestDetailsDialogComponent,
        RefPricesDialogComponent,
        // topbar components
        ContextMenu2Component,
        ContextMenuComponent,
        QuickPanelComponent,
        ScrollTopComponent,
        SearchResultComponent,
        MatSlideToggleModule,
        SplashScreenComponent,
        StickyToolbarComponent,
        Subheader1Component,
        Subheader2Component,
        Subheader3Component,
        Subheader4Component,
        Subheader5Component,
        SubheaderSearchComponent,
        LanguageSelectorComponent,
        NotificationComponent,
        NotificationDetailDialogComponent,
        QuickActionComponent,
        SearchDefaultComponent,
        SearchDropdownComponent,
        UserProfileComponent,
        UserProfile2Component,
        UserProfile3Component,
        CartComponent,
        ErrorComponent,
        HelpComponent,
        FeedbackComponent,
        FeedbackDialogComponent,
        PortalModule,
        QuickHelpComponent,
        MtFilterDropdownComponent,
        MtFilterDaterangeComponent,
        MtFilterTimeRangeComponent,
        MtSelectDropdownComponent,
        MatGridListModule,
        MtEditRequestMenuComponent,
        MtEditRequestValueComponent,
        MtReferencePriceTableComponent,
        MtFilterRemainTimeComponent,
        MtFilterDropdownMultiLevelComponent
    ],
    imports: [
        ClipboardModule,
        MatButtonToggleModule,
        MatGridListModule,
        MatSlideToggleModule,
        DragDropModule,
        MatExpansionModule,
        HttpClientModule,
        NgxPermissionsModule.forChild(),
        TranslateModule.forChild(),
        NgxMaskModule.forChild(options),
        NgbProgressbarModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxMatIntlTelInputModule,
        NgxIntlTelInputModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        InlineSVGModule,
        CoreModule,
        PortletModule,
        WidgetModule,
        //MaytechDetailDialogModule,
        PrintTemplateModule,
        // ng-bootstrap modules
        NgbDropdownModule,
        NgbTabsetModule,
        NgbTooltipModule,
        // angular material modules
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        CKEditorModule,
        OverlayModule,
        MatDividerModule,
        MatListModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    providers: [
        MetadataService,
        SupportTicketService,
        InterceptService,
        KtDialogService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        HelpEventService,
        ContextHelpService,
        NotificationService,
        GeneralSearchService,
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    ]
})
export class PartialsModule {
}
