// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// Partials for CRUD
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    ConfirmDialogComponent,
    SaveConfirmDialogComponent,
    RequestEditDialogComponent,
    ApproveEditDialogComponent,
    EditRequestListDialogComponent,
    EditRequestHistoryDialogComponent,
    EditRequestDetailsDialogComponent,
    RefPricesDialogComponent,
    SimpleDialogComponent
} from '../../../../views/partials/content/crud';

export enum AlertMessageType {
    Create,
    Read,
    Update,
    Delete
}

@Injectable()
export class LayoutUtilsService {
    /**
     * Service constructor
     *
     * @param snackBar: MatSnackBar
     * @param dialog: MatDialog
     */
    constructor(private snackBar: MatSnackBar,
        private dialog: MatDialog) { }

    /**
     * Showing (Mat-Snackbar) Notification
     *
     * @param message: string
     * @param type: MessageType
     * @param duration: number
     * @param showCloseButton: boolean
     * @param showUndoButton: boolean
     * @param undoButtonDuration: number
     * @param verticalPosition: 'top' | 'bottom' = 'top'
     */
    showActionNotification(
        _message: string,
        _type: AlertMessageType = AlertMessageType.Create,
        _duration: number = 10000,
        _showCloseButton: boolean = true,
        _showUndoButton: boolean = true,
        _undoButtonDuration: number = 3000,
        _verticalPosition: 'top' | 'bottom' = 'bottom'
    ) {
        const _data = {
            message: _message,
            snackBar: this.snackBar,
            showCloseButton: _showCloseButton,
            //showUndoButton: _showUndoButton,
            //undoButtonDuration: _undoButtonDuration,
            verticalPosition: _verticalPosition,
            type: _type,
            //action: 'Undo'
        };
        return this.snackBar.openFromComponent(ActionNotificationComponent, {
            //duration: _duration,
            duration: 2000,
            data: _data,
            verticalPosition: _verticalPosition
        });
    }

    /**
     * Showing Confirmation (Mat-Dialog) before Entity Removing
     *
     * @param title: stirng
     * @param description: stirng
     * @param waitDesciption: string
     */
    deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
        return this.dialog.open(DeleteEntityDialogComponent, {
            data: { title, description, waitDesciption },
            width: '440px'
        });
    }

    /**
     * Showing Fetching Window(Mat-Dialog)
     *
     * @param _data: any
     */
    fetchElements(_data) {
        return this.dialog.open(FetchEntityDialogComponent, {
            data: _data,
            width: '400px'
        });
    }

    /**
     * Showing Update Status for Entites Window
     *
     * @param title: string
     * @param statuses: string[]
     * @param messages: string[]
     */
    updateStatusForEntities(title, statuses, messages) {
        return this.dialog.open(UpdateStatusDialogComponent, {
            data: { title, statuses, messages },
            width: '480px'
        });
    }

    showSimpleDialog(title: string = '', description: string = '') {
        return this.dialog.open(SimpleDialogComponent, {
            data: { title, description },
            width: '440px'
        });
    }

    showConfirmDialog(title: string = '', description: string = '') {
        return this.dialog.open(ConfirmDialogComponent, {
            data: { title, description },
            width: '440px'
        });
    }

    showSaveConfirmDialog(title: string = '', description: string = '') {
        return this.dialog.open(SaveConfirmDialogComponent, {
            data: { title, description },
            width: '440px'
        });
    }

    showRequestEditDialog(data: any) {
        return this.dialog.open(RequestEditDialogComponent, {
            data: data,
            width: '440px'
        });
    }

    showApproveEditDialog(data: any) {
        return this.dialog.open(ApproveEditDialogComponent, {
            data: data,
            width: '440px'
        });
    }

    showEditRequestListDialog(data: any) {
        return this.dialog.open(EditRequestListDialogComponent, {
            data: data
        });
    }

    showEditRequestHistoryDialog(data: any) {
        return this.dialog.open(EditRequestHistoryDialogComponent, {
            data: data
        });
    }

    showEditRequestDetailsDialog(data: any) {
        return this.dialog.open(EditRequestDetailsDialogComponent, {
            data: data
        });
    }

    showRefPricesDialogComponent(data: any) {
        return this.dialog.open(RefPricesDialogComponent, {
            data: data,
            width: '440px'
        });
    }
}
